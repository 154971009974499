import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { sprintf } from "sprintf-js";
import Layout from "../components/layout";
import SEO from "../components/seo";
import breakpoint from "styled-components-breakpoint";

const Page = () => (
  <Layout>
    <SEO title="Stopwatch" />
    <Stopwatch />
  </Layout>
);

export default Page;

const Stopwatch = () => {
  const [running, setRunning] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [previouslyElapsedMillis, setPreviouslyElapsedMillis] = useState(0);
  const [, setRenderCount] = useState(0);

  let elapsedMillis = previouslyElapsedMillis;
  if (running) {
    elapsedMillis = Date.now() - startTime + previouslyElapsedMillis;
  }

  const millisSinceLastHours = elapsedMillis % (60 * 60 * 1000);
  const millisSinceLastMinutes = elapsedMillis % (60 * 1000);
  const hours = Math.floor(elapsedMillis / 1000 / 60 / 60)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor(millisSinceLastHours / 1000 / 60)
    .toString()
    .padStart(2, "0");
  const seconds = sprintf("%05.2f", millisSinceLastMinutes / 1000);

  useEffect(() => {
    let intervalHandle = null as number;
    if (running) {
      intervalHandle = setInterval(() => {
        setRenderCount(currentRenderCount => currentRenderCount + 1);
      }, 34);
    }

    return () => {
      if (intervalHandle != null) {
        clearInterval(intervalHandle);
      }
    };
  }, [running]);

  const onStopClick = () => {
    setRunning(false);
    setPreviouslyElapsedMillis(
      Date.now() - startTime + previouslyElapsedMillis
    );
  };
  const onStartClick = () => {
    setRunning(true);
    setStartTime(Date.now());
  };

  const onResetClick = () => {
    setRunning(false);
    setStartTime(null);
    setPreviouslyElapsedMillis(0);
  };

  return (
    <React.Fragment>
      <StopwatchContainer>
        <StopwatchName>Stopwatch ⏰</StopwatchName>
        <StopwatchDisplay>
          {hours}:{minutes}:{seconds}
        </StopwatchDisplay>
        <ButtonContainer>
          <StartStopButton
            running={running}
            onStopClick={onStopClick}
            onStartClick={onStartClick}
          />
          <ResetButton onClick={onResetClick}>Reset</ResetButton>
        </ButtonContainer>
      </StopwatchContainer>
    </React.Fragment>
  );
};

const StopwatchName = styled.h1`
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
`;

interface StartStopButtonProps {
  running: boolean;
  onStopClick: () => void;
  onStartClick: () => void;
}

const StartStopButton = ({
  running,
  onStopClick,
  onStartClick,
}: StartStopButtonProps) => {
  return (
    <StyledStartStopButton onClick={running ? onStopClick : onStartClick}>
      {running ? "Stop" : "Start"}
    </StyledStartStopButton>
  );
};

const StopwatchContainer = styled.div`
  border-style: double;
  padding: 45px;
  margin-bottom: 1rem;
`;

const StopwatchDisplay = styled.p`
  border: 2px double black;
  border-radius: 25px;
  padding: 16px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  ${breakpoint("tablet")`
  `}
  ${breakpoint("desktop")`
  `}
`;

const StyledStartStopButton = styled.button`
  border-radius: 25px;
  background: #73ad21;
  color: white;
  flex-basis: 50%;
  font-weight: bold;
  ${breakpoint("tablet")`
  `}
  ${breakpoint("desktop")`
  `}
`;

const ResetButton = styled.button`
  border-radius: 25px;
  background: #ee3b3b;
  color: white;
  flex-basis: 50%;
  font-weight: bold;
  ${breakpoint("tablet")`
  `}
  ${breakpoint("desktop")`
  `}
`;
